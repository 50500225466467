// 
// user.scss
// Use this to write your custom SCSS
//

.navbar-nav.fontsize-link-custom-1 .form-check-label{
    font-size: 13px !important;
}
//with no this class text-primary
.nav-link:not(.text-primary) {
    color: #868686 !important;
}

.nav-link:hover, .nav-link:focus {
    color: #231f20 !important;
}



@-webkit-keyframes swinging{
    0%{-webkit-transform: rotate(10deg);}
    50%{-webkit-transform: rotate(-5deg)}
    100%{-webkit-transform: rotate(10deg);}
}
 
@keyframes swinging{
    0%{transform: rotate(10deg);}
    50%{transform: rotate(-5deg)}
    100%{transform: rotate(10deg);}
}
 

@-webkit-keyframes sleeping {
    0%{ opacity:0;}
    50%{ opacity:1;}
    100%{ opacity:0;}
}
 
@keyframes sleeping {
    0%{ opacity:0;}
    50%{ opacity:1;}
    100%{ opacity:0;}
}
 
#swinger {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    transform-box: fill-box;
    -webkit-animation: swinging 3.5s ease-in-out forwards infinite;
    animation: swinging 3.5s ease-in-out forwards infinite;
}

#zone {
    opacity:0;
    -webkit-animation: sleeping 3.5s ease-in-out forwards infinite;
    animation: sleeping 3.5s ease-in-out forwards infinite;
}

#ztwo {
    opacity:0;
    -webkit-animation: sleeping 3.5s ease-in-out forwards infinite;
    animation: sleeping 3.5s ease-in-out forwards infinite;
    animation-delay:0.4s;
    -website-animation-delay:0.4s;
}

#zthree {
    opacity:0;
    -webkit-animation: sleeping 3.5s ease-in-out forwards infinite;
    animation: sleeping 3.5s ease-in-out forwards infinite;
    animation-delay:0.7s;
    -website-animation-delay:0.7s;
}

#zfour {
    opacity:0;
    -webkit-animation: sleeping 3.5s ease-in-out forwards infinite;
    animation: sleeping 3.5s ease-in-out forwards infinite;
    animation-delay:1s;
    -website-animation-delay:1s;
}

#zfive {
    opacity:0;
    -webkit-animation: sleeping 3.5s ease-in-out forwards infinite;
    animation: sleeping 3.5s ease-in-out forwards infinite;
    animation-delay:1.3s;
    -website-animation-delay:1.3s;
}

.container {
  text-align:center;
}

svg {
  max-width:1000px;
}

.fade:not(.show){
    opacity: 0;
    display: none;
}

.nav-link.active{
    color: #231f20 !important;
}

.nav-link.active:not(.text-primary){
    color: #231f20 !important;
}

.breadcrumb-item{
    font-weight: bold;
}

.swal2-styled{
    margin-left: 5px;
    margin-right: 5px;
}

.text-decoration-revert{
    text-decoration: revert !important;
}


.table > :not(caption) > * > *{
    background: transparent !important;
}

.table thead th{
 color: #231f20 !important;
 font-weight: bold !important;
 text-transform: capitalize !important;
 font-size: 15px;
}


.card-dashboard{
    border-radius: 30px;
    border-radius: 30px;
    box-shadow: 0px 0px 1.5rem rgb(0 0 0 / 15%)
}

.dataTables_empty{
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}


:where(.swal2-styled).swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #a7311e !important;
    color: #fff;
    font-size: 1em;
}

:where(.swal2-styled).swal2-confirm {
    border: 0;
    border-radius: 0.25em;
    background: initial;
    background-color: #a7311e !important;
    color: #fff;
    font-size: 1em;
  }
  :where(.swal2-styled).swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgba(167, 49, 30,0.5) !important;
  }


:where(.swal2-styled).swal2-deny {
  border: 1px solid #a7311e !important;
  border-radius: 0.25em !important;
  background: initial;
  background-color:transparent !important;
  color: #a7311e !important ;
  font-size: 1em;
}
:where(.swal2-styled).swal2-deny:focus {
  box-shadow: 0 0 0 3px rgba(220, 55, 65, 0.5);
}
:where(.swal2-styled).swal2-deny:hover {
    background-color: #a7311e !important;
    color: #fff !important;
}

.swal2-actions { 
    direction: rtl !important; 
}
