
[class*="select2"] {
    display: block;
  }
  
  .select2 {
    width: 100% !important;
  }
  
  .select2-hidden-accessible {
    display: none;
  }
  
  .select2-selection {
    cursor: pointer;
  }
  
  .select2-selection[aria-expanded="true"] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .select2-container {
    display: block;
  }
  
  .select2-dropdown {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .select2-search--dropdown {
    padding: 0.375rem 0.75rem;
  }
  
  .select2-search--dropdown.select2-search--hide {
    display: none;
  }
  
  .select2-search--dropdown .select2-search__field {
    width: 100%;
    height: calc(1.75em + 0.25rem + 2px);
    padding: 0.125rem 0.5rem;
    background-color: #1d1e20;
    border: 1px solid #D2DDEC;
    border-radius: 0.25rem;
    line-height: 1.75;
    font-size: 0.8125rem;
    color: #12263F;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .select2-search--dropdown .select2-search__field:focus {
    border-color: #2C7BE5;
    box-shadow: transparent;
    outline: none;
  }
  
  .select2-container--default .select2-results>.select2-results__options {
    max-height: 200px;
    overflow: auto;
  }
  
  .select2-results__options {
    padding-left: 0;
    margin-bottom: 0;
  }
  
  .select2-results__option {
    padding: 0.375rem 0.75rem;
    color: #fff;
  }
  
  .select2-results__option:not(.select2-results__message) {
    cursor: pointer;
  }
  
  .select2-results__option:not(.select2-results__message):hover,
  .select2-results__option:not(.select2-results__message):focus {
    color: #12263F;
  }
  
  .select2-results__option[aria-selected="true"],
  .select2-results__option--highlighted {
    color: #12263F;
  }
  
  .select2-selection--multiple {
    height: auto;
  }
  
  .select2-selection__rendered {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin: 0 -.25rem -.25rem 0;
  }
  
  .select2-selection__choice {
    display: inline-flex;
    align-items: center;
    padding-left: .375rem;
    padding-right: .375rem;
    margin: 0 .25rem 0 0;
    font-size: 0.8125rem;
    background-color: #2f3237;
    border-radius: 0.1875rem;
  }
  
  .select2-selection__choice__remove {
    order: 2;
    margin-left: .5rem;
    color: #fff;
    cursor: pointer;
  }
  
  .select2-selection__choice__remove:hover {
    color: #12263F;
  }
  
  .select2-search--inline .select2-search__field {
    height: calc(1em * 1.5);
    padding-bottom: .25rem;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    outline: none;
    color: #12263F;
  }
  
  .select2-search--inline .select2-search__field::-webkit-input-placeholder {
    color: #B1C2D9;
  }
  
  .select2-search--inline .select2-search__field::-moz-placeholder {
    color: #B1C2D9;
  }
  
  .select2-search--inline .select2-search__field:-ms-input-placeholder {
    color: #B1C2D9;
  }
  
  .select2-search--inline .select2-search__field::-ms-input-placeholder {
    color: #B1C2D9;
  }
  
  .select2-search--inline .select2-search__field::placeholder {
    color: #B1C2D9;
  }
  
  .select2-selection__placeholder {
    color: #B1C2D9;
  }
  
  .select2-container--bootstrap-5 {
    display: block;
  }
  select + .select2-container--bootstrap-5 {
    z-index: 1;
  }
  
  .select2-container--bootstrap-5 *:focus {
    outline: 0;
  }
  .select2-container--bootstrap-5 .select2-selection {
    width: 100%;
    min-height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #1d1e20;
    border: 1px solid #D2DDEC;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .select2-container--bootstrap-5 .select2-selection {
      transition: none;
    }
  }
  
  .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
    border-bottom: 0 solid transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection {
    border-top: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .select2-container--bootstrap-5 .select2-search {
    width: 100%;
  }
  .select2-container--bootstrap-5 .select2-search--inline .select2-search__field {
    vertical-align: top;
  }
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear,
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
    position: absolute;
    top: 50%;
    right: 2.25rem;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0.25em 0.25em;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
    transform: translateY(-50%);
  }
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear:hover,
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  }
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear > span,
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear > span {
    display: none;
  }
  
  .select2-container--bootstrap-5 + .select2-container--bootstrap-5 {
    z-index: 1056;
  }
  .select2-container--bootstrap-5 .select2-dropdown {
    z-index: 1056;
    overflow: hidden;
    color: #fff;
    background-color: #1d1e20;
    border-color: #D2DDEC;
    border-radius: 0.25rem;
  }
  .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--below {
    border-top: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--above {
    border-bottom: 0 solid transparent;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .select2-container--bootstrap-5 .select2-dropdown .select2-search {
    padding: 0.375rem 0.75rem;
  }
  .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-color: #1d1e20;
    background-clip: padding-box;
    border: 1px solid #D2DDEC;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
      transition: none;
    }
  }
  
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options:not(.select2-results__options--nested) {
    max-height: 15rem;
    overflow-y: auto;
  }
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__message {
    color: #fff;
  }
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--highlighted {
    color: #fff;
    background-color: #a3a9aa;
  }
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected, .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {
    color: #fff;
    background-color: #111213;
  }
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--disabled, .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-disabled=true] {
    color: #fff;
  }
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] {
    padding: 0;
  }
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
    padding: 0.375rem 0.375rem;
    font-weight: 500;
    line-height: 1.5;
    color: #fff;
  }
  .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
    padding: 0.375rem 0.75rem;
  }
  
  .select2-container--bootstrap-5 .select2-selection--single {
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
  }
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered {
    padding: 0;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
  }
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered .select2-selection__placeholder {
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
  }
  .select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered .select2-selection__arrow {
    display: none;
  }
  
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0;
    margin: 0;
    list-style: none;
  }
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.35em 0.65em;
    margin-right: 0.375rem;
    margin-bottom: 0.375rem;
    font-size: 0.8125rem;
    color: #fff;
    cursor: auto;
    border: 1px solid #D2DDEC;
    border-radius: 0.25rem;
  }
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    width: 0.75rem;
    height: 0.75rem;
    padding: 0.25em 0.25em;
    margin-right: 0.25rem;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
    border: 0;
  }
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75rem auto no-repeat;
  }
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove > span {
    display: none;
  }
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-search {
    display: none;
    width: 100%;
    height: 1.5rem;
  }
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field {
    width: 100% !important;
    height: 1.75em;
    margin-top: 6px;
    margin-bottom: 0px;
    margin-left: 0;
    background-color: transparent;
    overflow: hidden;
    resize: none;
    padding: 0 0.75rem;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #fff;
    background-clip: padding-box;
    border: 1px solid #D2DDEC;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    
  }
  .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
    right: 0.75rem;
  }
  
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection {
    color: #fff;
    cursor: not-allowed;
    background-color: #a3a9aa;
    border-color: #D2DDEC;
    box-shadow: none;
  }
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__clear, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__clear {
    display: none;
  }
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__choice, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__choice {
    cursor: not-allowed;
  }
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
    display: none;
  }
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__rendered:not(:empty), .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__rendered:not(:empty) {
    padding-bottom: 0;
  }
  .select2-container--bootstrap-5.select2-container--disabled .select2-selection--multiple .select2-selection__rendered:not(:empty) + .select2-search, .select2-container--bootstrap-5.select2-container--disabled.select2-container--focus .select2-selection--multiple .select2-selection__rendered:not(:empty) + .select2-search {
    display: none;
  }
  
  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu).select2-container--bootstrap-5 .select2-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu).select2-container--bootstrap-5 .select2-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .input-group-text ~ .select2-container--bootstrap-5 .select2-selection,
  .input-group > .btn ~ .select2-container--bootstrap-5 .select2-selection,
  .input-group > .dropdown-menu ~ .select2-container--bootstrap-5 .select2-selection {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .input-group .select2-container--bootstrap-5 {
    flex-grow: 1;
  }
  .input-group .select2-container--bootstrap-5 .select2-selection {
    height: 100%;
  }
  
  .is-valid + .select2-container--bootstrap-5 .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5 .select2-selection {
    border-color: #198754;
  }
  .is-valid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .is-valid + .select2-container--bootstrap-5.select2-container--open .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5.select2-container--open .select2-selection {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
  }
  .is-valid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
    border-bottom: 0 solid transparent;
  }
  .is-valid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection, .was-validated select:valid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection {
    border-top: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .is-invalid + .select2-container--bootstrap-5 .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5 .select2-selection {
    border-color: #dc3545;
  }
  .is-invalid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .is-invalid + .select2-container--bootstrap-5.select2-container--open .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5.select2-container--focus .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5.select2-container--open .select2-selection {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
  }
  .is-invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--below .select2-selection {
    border-bottom: 0 solid transparent;
  }
  .is-invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection, .was-validated select:invalid + .select2-container--bootstrap-5.select2-container--open.select2-container--above .select2-selection {
    border-top: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .select2-container--bootstrap-5 .select2--small.select2-selection {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }
  .select2-container--bootstrap-5 .select2--small.select2-selection--single .select2-selection__clear,
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__clear {
    width: 0.5rem;
    height: 0.5rem;
    padding: 0.125rem 0.125rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  .select2-container--bootstrap-5 .select2--small.select2-selection--single .select2-selection__clear:hover,
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__clear:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  .select2-container--bootstrap-5 .select2--small.select2-selection--single .select2-search,
  .select2-container--bootstrap-5 .select2--small.select2-selection--single .select2-search .select2-search__field,
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-search,
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-search .select2-search__field {
    height: 1.5em;
  }
  .select2-container--bootstrap-5 .select2--small.select2-dropdown {
    border-radius: 0.2rem;
  }
  .select2-container--bootstrap-5 .select2--small.select2-dropdown.select2-dropdown--below {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .select2-container--bootstrap-5 .select2--small.select2-dropdown.select2-dropdown--above {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-search .select2-search__field {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  .select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-results__options .select2-results__option {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  .select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
    padding: 0.25rem 0.25rem;
  }
  .select2-container--bootstrap-5 .select2--small.select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
    padding: 0.25rem 0.5rem;
  }
  .select2-container--bootstrap-5 .select2--small.select2-selection--single {
    padding: 0.25rem 2.25rem 0.25rem 0.5rem;
  }
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    padding: 0.35em 0.65em;
    font-size: 0.875rem;
  }
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    width: 0.5rem;
    height: 0.5rem;
    padding: 0.125rem 0.125rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  .select2-container--bootstrap-5 .select2--small.select2-selection--multiple .select2-selection__clear {
    right: 0.5rem;
  }
  .select2-container--bootstrap-5 .select2--large.select2-selection {
    min-height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
  }
  .select2-container--bootstrap-5 .select2--large.select2-selection--single .select2-selection__clear,
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__clear {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem 0.5rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  .select2-container--bootstrap-5 .select2--large.select2-selection--single .select2-selection__clear:hover,
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__clear:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  .select2-container--bootstrap-5 .select2--large.select2-selection--single .select2-search,
  .select2-container--bootstrap-5 .select2--large.select2-selection--single .select2-search .select2-search__field,
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-search,
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-search .select2-search__field {
    height: 1.5em;
  }
  .select2-container--bootstrap-5 .select2--large.select2-dropdown {
    border-radius: 0.3rem;
  }
  .select2-container--bootstrap-5 .select2--large.select2-dropdown.select2-dropdown--below {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .select2-container--bootstrap-5 .select2--large.select2-dropdown.select2-dropdown--above {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-search .select2-search__field {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }
  .select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-results__options .select2-results__option {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }
  .select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
    padding: 0.5rem 0.5rem;
  }
  .select2-container--bootstrap-5 .select2--large.select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
    padding: 0.5rem 1rem;
  }
  .select2-container--bootstrap-5 .select2--large.select2-selection--single {
    padding: 0.5rem 2.25rem 0.5rem 1rem;
  }
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    padding: 0.35em 0.65em;
    font-size: 1.25rem;
  }
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem 0.5rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  .select2-container--bootstrap-5 .select2--large.select2-selection--multiple .select2-selection__clear {
    right: 1rem;
  }
  
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear,
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
    width: 0.5rem;
    height: 0.5rem;
    padding: 0.125rem 0.125rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear:hover,
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--single .select2-search,
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--single .select2-search .select2-search__field,
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-search,
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field {
    height: 1.5em;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown {
    border-radius: 0.2rem;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--below {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--above {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
    padding: 0.25rem 0.25rem;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
    padding: 0.25rem 0.5rem;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--single {
    padding: 0.25rem 2.25rem 0.25rem 0.5rem;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    padding: 0.35em 0.65em;
    font-size: 0.875rem;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    width: 0.5rem;
    height: 0.5rem;
    padding: 0.125rem 0.125rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.5rem auto no-repeat;
  }
  .form-select-sm ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
    right: 0.5rem;
  }
  
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection {
    min-height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear,
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem 0.5rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single .select2-selection__clear:hover,
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single .select2-search,
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single .select2-search .select2-search__field,
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-search,
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-search .select2-search__field {
    height: 1.5em;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown {
    border-radius: 0.3rem;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--below {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown.select2-dropdown--above {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__group {
    padding: 0.5rem 0.5rem;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[role=group] .select2-results__options--nested .select2-results__option {
    padding: 0.5rem 1rem;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--single {
    padding: 0.5rem 2.25rem 0.5rem 1rem;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
    padding: 0.35em 0.65em;
    font-size: 1.25rem;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem 0.5rem;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23676a6d'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1rem auto no-repeat;
  }
  .form-select-lg ~ .select2-container--bootstrap-5 .select2-selection--multiple .select2-selection__clear {
    right: 1rem;
  }
  
  .select2-container--focus .select2-search--inline{
    display: block !important;
  }
  .select2-container--disabled .select2-search--inline{
    display: none !important;
  }