// 
// user-variables.scss
// Use this to overwrite Bootstrap and theme variables
//

// Example of a variable override to change theme's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$primary: #a7311e !default;
$black: #231f20 !default;
$black-dark: #231f20 !default;

